<script>
import { required, email } from "vuelidate/lib/validators";
import AuthServiceApi from "@/service/api/auth.js"
export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      resetSuccess:false,
      isResetError: false,
      successMessage:null,
      reseting :false,
    };
  },
  validations: {
    email: { required, email },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
  
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.reseting = true;
        AuthServiceApi.passwordReset(this.email).then((response)=>{
           this.successMessage = response.data.message;
             this.isResetError = false;
            this.resetSuccess = true;
        }).catch((error)=>{
          if(error.response.status === 422){
             this.error = error.response.data.errors.email[0];
          }
          else{
              this.error = error.response.data.error;
          }
        
          this.resetSuccess = false;
          this.isResetError = true;
        
        
        }).finally(()=>{
           this.reseting = false;
        })
      
      }
    },
  
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-7">
            <div class="authentication-bg">
    
              <img src="@/assets/images/login-img.png" alt="" />
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to Sheesh.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                          >{{ error }}</b-alert
                        >
                        <b-alert
                          v-model="resetSuccess"
                          class="mb-4"
                          variant="success"
                          dismissible
                          >{{ successMessage }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToReset"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                               <span v-if="!reseting">Reset</span> <span v-else>Resetting...</span>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Log in</router-link
                          >
                        </p>
                        <p>
                          © 2021 Sheesh. Developed
                          <i class="mdi mdi-heart text-danger"></i> by
                          MVP-APPS.AE
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</template>
